import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import FirebaseContext from './common/contexts/FirebaseContext';

firebase.initializeApp({
  apiKey: "AIzaSyBrzYW8fAiI0jyf1dab_wHKbQ1KPBS7o5w",
  authDomain: "easybus-linen.firebaseapp.com",
  databaseURL: "https://easybus-linen.firebaseio.com",
  projectId: "easybus-linen",
  storageBucket: "easybus-linen.appspot.com",
  messagingSenderId: "266884765533",
  appId: "1:266884765533:web:60c199ecd70ce633"
});

ReactDOM.render(
  <FirebaseContext.Provider value={firebase}>
    <App />
  </FirebaseContext.Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: () => window.postMessage({updateAvailable: true}, '*')
});