import { useContext, useState, useEffect } from "react";
import FirebaseContext from "../contexts/FirebaseContext";

export default (appId, language) => {
  const firebase = useContext(FirebaseContext);
  const [application, setApplication] = useState({});

  useEffect(() => {
    if (appId&&language) {
      const handler = firebase.firestore().doc(`applications/${appId}/languages/${language}`)
        .onSnapshot((app) => setApplication(app.data()||{}));
      return () => handler();
    }
  }, [appId, language]);

  return application;
}