export const CODE_EN = 'en';
export const CODE_MS = 'ms';
export const CODE_ZH_CN = 'zh-CN';
export const CODE_JA = 'ja';
export const CODE_TH = 'th';

export const LANGUAGE_EN = { code: CODE_EN, display: 'English' };
export const LANGUAGE_JA = { code: CODE_JA, display: '日本語' };
export const LANGUAGE_ZH_CN = { code: CODE_ZH_CN, display: '中文(简体)' };
export const LANGUAGE_MS = { code: CODE_MS, display: 'Melayu' };
export const LANGUAGE_TH = { code: CODE_TH, display: 'ไทย' };

export const LANGUAGES = [
  LANGUAGE_EN,
  LANGUAGE_ZH_CN
];