import { useContext, useState, useEffect } from 'react';
import FirebaseContext from '../contexts/FirebaseContext';

export default (accountId) => {
  const [account, setAccount] = useState(null);
  const firebase = useContext(FirebaseContext);

  useEffect(() => {
    setAccount(null);
    if (accountId) {
      const handler = firebase.firestore().doc(`accounts/${accountId}`)
        .onSnapshot((account) => setAccount(account));
      return () => handler();
    }
  }, [accountId]);

  return account;
}