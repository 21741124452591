import React, { Component } from 'react';
import { Card, Button } from 'antd';
import { SyncOutlined } from '@ant-design/icons';

export default class ErrorBoundary extends Component {
  state = {}
  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ error });
    console.log(error, error&&error.message);
    if (error&&error.message&&(error.message.indexOf(`missing:`)>=0)) {
      window.location.reload(true);
    }
  }
  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return (
        <div className='padding'>
          <Card loading actions={[
            <Button key='reload' size='large' className='full-width' icon={<SyncOutlined/>} onClick={()=>window.location.reload(true)}>RELOAD</Button>
          ]}/>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}