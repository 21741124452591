import React, { Suspense, lazy, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import useLoginStatus from './common/hooks/useLoginStatus';
import Loading from './common/widgets/Loading';
import SignInContext from './common/contexts/SignInContext';
import RoleContext from './common/contexts/RoleContext';
import FactoryContext from './common/contexts/FactoryContext';
import HotelContext from './common/contexts/HotelContext';
import PermissionsContext from './common/contexts/PermissionsContext';
import CustomerContext from './common/contexts/CustomerContext';
import InvoiceRuleContext from './common/contexts/InvoiceRuleContext';
import isMobile from './common/utils/isMobile';
import { ROLE_ADMIN, ROLE_DRIVER, ROLE_CUSTOMER_MANAGER, ROLE_SUPERADMIN, ROLE_WORKER_MANAGER, ROLE_WORKER } from './common/constants/roles';
import LanguageContext from './common/contexts/LanguageContext';
import { CODE_EN } from './common/constants/language';
import FirebaseContext from './common/contexts/FirebaseContext';
import ErrorBoundary from './common/widgets/ErrorBoundary';
import useApplication from './common/hooks/useApplication';
import ApplicationContext from './common/contexts/ApplicationContext';
import useAccount from './common/hooks/useAccount';
import { INVOICE_RULE_LATEST } from './common/constants/invoice';

const SuperAdminConsole = lazy(()=>import('./SuperAdminConsole/IndexPage'));
const FactoryApp = lazy(()=>import('./FactoryApp/IndexPage'));
const FactoryConsole = lazy(()=>import('./FactoryConsole/IndexPage'));
const CustomerApp = lazy(()=>import('./CustomerApp/IndexPage'));
const CustomerConsole = lazy(()=>import('./CustomerConsole/IndexPage'));
const DriverApp = lazy(()=>import('./DriverApp/IndexPage'));
const LoginPage = lazy(()=>import('./common/LoginPage'));

export default function App () {
  const firebase = useContext(FirebaseContext);
  const account = useLoginStatus();
  const dbAccount = useAccount(account&&account.uid);
  const mobile = isMobile();
  const { language, permissions } = (dbAccount&&dbAccount.data())||{};
  const { role, factoryId, hotelId, customerId } = account||{};
  const [invoiceRule, setInvoiceRule] = useState(INVOICE_RULE_LATEST);

  useEffect(() => {
    if (factoryId) {
      const handler = firebase.firestore().doc(`factories/${factoryId}/settings/linen`)
        .onSnapshot((setting) => {
          if (setting.get('invoiceRule')) {
            setInvoiceRule(setting.get('invoiceRule'));
          }
        });

      return handler;
    }
  }, [factoryId]);

  let redirectPath, applicationId;
  if (account) {
    if (role === ROLE_SUPERADMIN) {
      redirectPath = '/superadmin';
    } else if (factoryId&&(role === ROLE_ADMIN)) {
      redirectPath = '/factory/console';
      applicationId = 'factoryconsole';
    } else if (factoryId&&(
      (role === ROLE_WORKER_MANAGER)
      || (role === ROLE_WORKER)
    )) {
      redirectPath = '/factory/app';
      applicationId = 'factoryapp';
    } else if (factoryId&&(role === ROLE_DRIVER)) {
      redirectPath = '/driver/app';
      applicationId = 'driverapp';
    } else if (factoryId&&(role === ROLE_CUSTOMER_MANAGER)) {
      if (mobile) {
        redirectPath = '/customer/app';
        applicationId = 'customerapp';
      } else {
        redirectPath = '/customer/console';
        applicationId = 'customerconsole';
      }
    } else {
      firebase.auth().signOut();
    }
  } else if (account === false) {
    redirectPath = '/login';
  }

  const application = useApplication(applicationId, language||CODE_EN);

  return (
    <ApplicationContext.Provider value={application}>
      <SignInContext.Provider value={account}>
        <PermissionsContext.Provider value={(dbAccount&&dbAccount.data())||{}}>
        <RoleContext.Provider value={role}>
          <LanguageContext.Provider value={language||CODE_EN}>
            <FactoryContext.Provider value={factoryId}>
              <HotelContext.Provider value={hotelId}>
                <CustomerContext.Provider value={customerId}>
                  <InvoiceRuleContext.Provider value={invoiceRule}>
                    <ErrorBoundary>
                      {(account===null)?<Loading tap='Logging in...'/>:
                        <Router>
                          <Suspense fallback={<Loading/>}>
                            <Switch>
                              {(redirectPath==='/superadmin')&&<Route path="/superadmin" component={SuperAdminConsole} />}
                              {(redirectPath==='/factory/app')&&<Route path="/factory/app" component={FactoryApp} />}
                              {(redirectPath==='/factory/console')&&<Route path="/factory/console" component={FactoryConsole} />}
                              {(redirectPath==='/customer/app')&&<Route path="/customer/app" component={CustomerApp} />}
                              {(redirectPath==='/customer/console')&&<Route path="/customer/console" component={CustomerConsole} />}
                              {(redirectPath==='/driver/app')&&<Route path="/driver/app" component={DriverApp} />}
                              {(redirectPath==='/login')&&<Route path="/login" exact component={LoginPage} />}
                              {redirectPath&&<Redirect push={false} to={redirectPath}/>}
                            </Switch>
                          </Suspense>
                        </Router>
                      }
                    </ErrorBoundary>
                  </InvoiceRuleContext.Provider>
                </CustomerContext.Provider>
              </HotelContext.Provider>
            </FactoryContext.Provider>
          </LanguageContext.Provider>
        </RoleContext.Provider>
        </PermissionsContext.Provider>
      </SignInContext.Provider>
    </ApplicationContext.Provider>
  );
};