import { useContext, useState, useEffect } from 'react';
import FirebaseContext from '../contexts/FirebaseContext';

export default () => {
  const [account, setAccount] = useState(null);
  const firebase = useContext(FirebaseContext);

  useEffect(() => {
    const handler = firebase.auth().onAuthStateChanged(async (account) => {
      if (account) {
        const token = await account.getIdTokenResult();
        if (token&&token.claims) {
          setAccount({
            uid: account.uid,
            name: account.displayName||'',
            ...(account.email&&{email: account.email}),
            role: token.claims.role,
            workerId: token.claims.workerId,
            factoryId: token.claims.factoryId,
            hotelId: token.claims.hotelId,
            customerId: token.claims.customerId,
            subAdmin: token.claims.subAdmin
          });
        } else {
          setAccount(false);
        }
      } else {
        setAccount(false);
      }
    });

    return () => handler();
  }, []);

  return account;
}