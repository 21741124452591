import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export default (props) => {
  return (
    <div className='full-page padding flex flex-center flex-align-center'>
      <Spin tip={props.tip} indicator={<LoadingOutlined style={{fontSize:40}} spin />} />
    </div>
  );
}